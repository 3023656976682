import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useCollapse } from 'react-collapsed'
import FeatureSubCategory from './FeatureSubCategory';
import Feature from './Feature';
import { HomeFeaturesContext } from './contexts';



const FeatureCategory = (props) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const homeFeatures = useContext(HomeFeaturesContext).homeFeatures

  const subCategories = () => {
    return props.featureCategory.sub_categories.map((subCategory) => {
      return (
        <div key={subCategory.id}>
          <FeatureSubCategory featureSubCategory={subCategory} />
        </div>
      );
    });
  }

  const getCategoryFeatures = (featureCategory) => {
    return homeFeatures.filter((homeFeature) => {
      return homeFeature.feature.category_id === featureCategory.id;
    });
  }


  const features = () => {
    const matchingFeatures = getCategoryFeatures(props.featureCategory);
    return matchingFeatures.map((matchingFeature) => {
      return (
        <div key={matchingFeature.id}>
          <Feature homeFeature={matchingFeature} />
        </div>
      );
    });
  }

  return (
    <div class="category" {...getToggleProps()}>
      <div>
        <div class="header">
          <div class="collapsible-control">
            <i class={`fa-solid ${isExpanded ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
            <h2>{ props.featureCategory.name }</h2>
          </div>
          <div>
            
          </div>
        </div>

        <div {...getCollapseProps()}>
          <div class="feature-category">
            {features()}
            <div className="feature-subcategories">
              {subCategories()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


FeatureCategory.propTypes = {
  featureCategory: PropTypes.array.isRequired,
};


export default FeatureCategory;
