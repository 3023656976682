import React from "react";
import HomeProfile from "./HomeProfile";

const App = (props) => {
  return (
    console.log("props", props),
    <div>
      <HomeProfile url={props.url} />
    </div>
  );
};

export default App;