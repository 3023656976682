import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { HomeFeaturesContext } from './contexts';

const Feature = (props) => {
  const context = useContext(HomeFeaturesContext)
  const home = context.home

  console.log(context)

  const handleCardClick = (e) => {
    e.stopPropagation();
  }

  const linkToComponent = (component) => {
    return `/homes/${home.id}/components/${component.id}`
  }

  return (
    <div className="feature card" onClick={handleCardClick} id={`feature-${props.homeFeature.feature.id}`}>
      <header class="card-header">
        <p class="card-header-title">
          {props.homeFeature.feature?.name}
          <a href={`/questions/${props.homeFeature.feature?.id}/feature`} className="button is-small">
            <span className="icon is-small">
              <i className="fa-regular fa-pen-to-square"></i>
            </span>
            <span>
              Change
            </span>
          </a>
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <div className="home-components">
            { props.homeFeature.not_applicable &&
              <p>Not applicable</p>
            }
            { !props.homeFeature.not_applicable && props.homeFeature.active_components?.length == 0 && 
              <div className="component card">
                <div className="card-content">
                  <p>Has {props.homeFeature.feature?.name}</p>
                </div>
              </div>
            }
            {props.homeFeature.active_components?.map((component) => {
              return (
                <div className="component card" key={component.id}>
                  <div className="card-content">
                    <p className='title'><a href={linkToComponent(component)}>{component.name}</a></p>
                    <p>{ component.description }</p>
                  </div>
                  { component.age && 
                    <div>
                      <p>Age: { component.age }</p>
                    </div>
                  }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Feature.propTypes = {
  homeFeature: PropTypes.object.isRequired,
};

export default Feature;