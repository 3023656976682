import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FeatureCategory from './FeatureCategory';
import { HomeFeaturesContext } from './contexts';


const HomeProfile = (props) => {
  const [data, setData] = useState(null);

  const featureCategories = () => {
    return data.feature_categories.map((featureCategory) => {
      return (
        <div key={featureCategory.id}>
          <FeatureCategory featureCategory={featureCategory} />
        </div>
      );
    });
  
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(props.url);
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setData(null);
      }
    };

    fetchData();
  }, [props.url]);

  return (
    <div>
      <HomeFeaturesContext.Provider value={{homeFeatures: data?.home_features, home: data?.home}}>
        {data ? featureCategories() : 'Loading...'}
      </HomeFeaturesContext.Provider>
    </div>
  );
};


HomeProfile.propTypes = {
  url: PropTypes.string.isRequired,
};


export default HomeProfile;
