console.log("asdf")
// expand and collapse

const expandTriggers = document.querySelectorAll('.collapsible');
for (const item of expandTriggers) {
  item.addEventListener('click', (e) => {
    console.log("item", item)
    e.preventDefault();
    item.classList.toggle('is-collapsed');
  });
}