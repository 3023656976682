// Style
// import "@sjmc11/tourguidejs/src/scss/tour.scss"
// JS
import { TourGuideClient } from "@sjmc11/tourguidejs"


document.addEventListener('turbo:load', () => {
  const steps = [
    {
      content: "To get started with this online platform, simply answer questions about your home. (Final version will only show this tour once.)",
      title: "Welcome aboard 👋",
      target: null,
      order: 1,
      group: "onboarding"
    },
    {
      content: "Go through each question and choose the item you have. Sometimes, you might need to pick multiple options, like if your home has both metal siding and faux stone. Choose all that apply.",
      title: "Answer questions about your home",
      target: '[data-tg-tour-target="question"]',
      order: 2,
      group: "onboarding"
    },
    {
      content: "As you answer questions, you'll build your home profile. Go here to view details about your home or change your answers.",
      title: "Home Profile",
      target: document.querySelector('[data-tg-tour-target="home"]'),
      order: 3,
      group: "onboarding"
    },
    {
      content: "As you answer questions, we'll recommend tasks to keep your home safe and protect your investment.",
      title: "Recommendations",
      target: document.querySelector('[data-tg-tour-target="recommendations"]'),
      order: 4,
      group: "onboarding"
    },
    {
      content: "Tasks that you choose to complete will be added to your To-Do list.",
      title: "My To-Do List",
      target: document.querySelector('[data-tg-tour-target="todos"]'),
      order: 5,
      group: "onboarding"
    }
  ]
  const tourGuideClient = new TourGuideClient({
    steps: steps,
    rememberStep: true,
    dialogWidth: 420,
    dialogMaxWidth: 420
  })

  setTimeout(() => {
    tourGuideClient.start()
  }, 1500)

})
