import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["flippable"]

  animate(event) {
    console.log(event)
    event.preventDefault()
    event.stopImmediatePropagation()
    this.flippableTarget.classList.toggle('is-flipped')
  }
}