import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="question"
export default class extends Controller {
  static targets = ["content"]
  connect() {
    fetch('/questions/next').then(response => response.text()).then(html => {
      this.element.innerHTML = html

      const expandableTriggers = document.querySelectorAll('.expandable-link')

      for (const item of expandableTriggers) {
        item.addEventListener('click', (e) => {
          console.log("item", item)
          e.preventDefault();
          e.stopImmediatePropagation();
          const target = item.dataset.target;
          const $target = document.getElementById(target);
          // $target.classList.toggle('is-hidden');
          // $target.parentElement.classList.toggle('is-hidden');
          const expandableContent = $target.closest('.expandable-content');
          expandableContent.classList.add('expanded');
        })
      }
    })
  }
}
