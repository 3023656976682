// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./scripts/modals.js"
import "./scripts/expandable.js"
import "./scripts/trix_overrides.js"
import "trix"
import "@rails/actiontext"
import "./scripts/tour.js"

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";

document.addEventListener('turbo:load', () => {
  const homeProfileElement = document.getElementById("home-profile")
  if (homeProfileElement) {
    const root = ReactDOM.createRoot(homeProfileElement)
    root.render(<App { ...homeProfileElement.dataset } />)
  }
})

document.addEventListener('turbo:submit-end', () => {
  console.log('turbo:submit-end fired');
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
});


document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Add a click event on each of them
  $navbarBurgers.forEach(el => {
    el.addEventListener('click', (e) => {

      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');

    });
  });

  // Functions to open and close a modal
  // function openModal($el) {
  //   $el.classList.add('is-active');
  // }

  // function closeModal($el) {
  //   $el.classList.remove('is-active');
  // }

  // function closeAllModals() {
  //   (document.querySelectorAll('.modal') || []).forEach(($modal) => {
  //     closeModal($modal);
  //   })
  // }

  // const modalTriggers = document.querySelectorAll('.js-modal-trigger');
  // console.log("modalTriggers", modalTriggers)
  // for (const item of modalTriggers) {
  //   console.log("item", item)
  //   item.addEventListener('click', (e) => {
  //     e.preventDefault();
  //     const modal = item.dataset.target;
  //     const $target = document.getElementById(modal);
  //     openModal($target);
  //   });
  // }

  // document.addEventListener('click', function (event) {
  //   if (event.target.classList.contains('modal-trigger')) {
  //     event.preventDefault();
  //     let $trigger = event.target;
  //     let modal = $trigger.dataset.target;
  //     let $target = document.getElementById(modal);
  //     openModal($target);
  //   }
  // })

  // document.addEventListener('click', function (event) {
  //   if (event.target.classList.contains('modal-close') || event.target.classList.contains('modal-background')) {
  //     console.log("modal-close")
  //     event.preventDefault();
  //     let $trigger = event.target;
  //     let modal = $trigger.closest('.modal').id;
  //     let $target = document.getElementById(modal);
  //     closeModal($target);
  //   }
  // })

  // Add a click event on various child elements to close the parent modal
  // (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
  //   const $target = $close.closest('.modal');

  //   $close.addEventListener('click', (e) => {
  //     e.preventDefault();
  //     closeModal($target);
  //   });
  // });

  // Add a keyboard event to close all modals
  // document.addEventListener('keydown', (event) => {
  //   if (event.code === 'Escape') {
  //     closeAllModals();
  //   }
  // });



});
