window.addEventListener("trix-file-accept", function (event) {
  const acceptedTypes = ['image/jpeg', 'image/png']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Sorry, that is not a valid file type. Please upload a jpeg or png image.")
  }
})

window.addEventListener("trix-file-accept", function (event) {
  const maxFileSize = 2400 * 2400 // 1MB 
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Please choose a file under 5MB.")
  }
})